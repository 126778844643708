import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import classnames from "classnames";
import TrustStickyFooter, {
  shouldHideTrustStickyFooter,
  trustStickyFooterClosed,
} from "storefront/components/TrustStickyFooter";
import Header from "./Header";
import Footer from "./Footer";
import { Meta, defaultMeta } from "./Head/Meta";
import DocumentHead from "./DocumentHead";
import styles from "./Layout.module.scss";

const SignupExperiment = dynamic(() => import("../SignupExperiment"), {
  ssr: false,
});

type Props = {
  mainClassName?: string;
  children: React.ReactNode;
  meta?: Meta;
  paddingBottom?: number;
  trustFooter?: boolean;
  footer?: boolean;
};

const Layout = ({
  mainClassName,
  children,
  meta,
  paddingBottom,
  trustFooter = true,
  footer = true,
}: Props) => {
  const [showTrustStickyFooter, setShowTrustStickyFooter] =
    useState<boolean>(false);

  useEffect(() => {
    setShowTrustStickyFooter(
      trustFooter &&
        !trustStickyFooterClosed() &&
        !shouldHideTrustStickyFooter(),
    );
  }, [trustFooter]);

  return (
    <div className={classnames(styles.pageLayout)} style={{ paddingBottom }}>
      <DocumentHead meta={meta || defaultMeta()} doNotTrack={false} />

      <Header />

      <div id="flash" />

      <div id="VacationBannerContainer" />

      <main className={classnames(styles.content, mainClassName)}>
        {children}
      </main>

      {footer ? <Footer /> : null}

      <SignupExperiment />

      {showTrustStickyFooter ? <TrustStickyFooter /> : null}
    </div>
  );
};

export default Layout;
