import { Department, MENSWEAR } from "storefront/Department";
import { categorySlugs } from "storefront/Categories";
import type { CategoriesV2 } from "storefront/Categories";
import type { ShippingLabelType } from "storefront/ShippingLabel";

/**
 * @namespace Category
 * @description Category here actually represents a subcategory.
 * TopLevelCategory has a subcategories key.
 *
 * @todo Ideally, subcategories will be an optional key on Category. Current codepaths
 * prevent us from taking this step.
 */
export type Category = {
  name: string;
  path: string;
  slug: string;
  // synonymPaths is only on subcategories
  synonymPaths?: Array<string>;
  avgShipping: number;
};

export type CategoryV2 = Category & {
  sortIndex: number;
  department: Department;
  groupBy: string;
  taxCode: string;
  labelRecommendation?: ShippingLabelType;
};

// L3 category based on rails model
export type CategoryV3 = {
  name: string;
  path: string;
  slug: string;
};

export type WithSubcategories<T> = {
  subcategories: Array<T>;
};

export type TopLevelCategory = Category & WithSubcategories<Category>;

export type TopLevelCategoryV2 = CategoryV2 & WithSubcategories<CategoryV2>;

export type AnyCategory = Category | TopLevelCategory;
export type AnyCategoryV2 = CategoryV2 | TopLevelCategoryV2;

export const PATH_SEPARATOR = ".";

export const emptyCategory: TopLevelCategory = {
  name: "",
  path: "",
  slug: "",
  avgShipping: 0,
  subcategories: [
    {
      name: "",
      path: "",
      slug: "",
      avgShipping: 0,
      synonymPaths: [],
    },
  ],
};

export const emptyCategoryV2: TopLevelCategoryV2 = {
  name: "",
  path: "",
  slug: "",
  avgShipping: 0,
  sortIndex: 0,
  groupBy: "",
  department: MENSWEAR,
  taxCode: "",
  subcategories: [
    {
      name: "",
      path: "",
      slug: "",
      avgShipping: 0,
      synonymPaths: [],
      sortIndex: 0,
      groupBy: "",
      department: MENSWEAR,
      taxCode: "",
    },
  ],
};

export const emptyCategoryV3: CategoryV3 = {
  name: "",
  path: "",
  slug: "",
};

export const isCategoryV2 = (
  category: Category | CategoryV2,
): category is CategoryV2 => {
  const hasDepartment = Object.prototype.hasOwnProperty.call(
    category,
    "department",
  );

  return hasDepartment;
};

export const isCategorySlug = (
  category: string,
  categories: CategoriesV2,
): boolean => categorySlugs(categories).includes(category);
